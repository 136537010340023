<template>
  <v-container fluid>
    <v-row class="mb-2 mt-1" style="align-items: center;">
      <v-btn icon :to="{ name: 'lego' }" exact>
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>

      <v-subheader>{{ set.name }} ({{ set._id }})</v-subheader>

      <a :href="set.url" target="_blank">Link</a>
    </v-row>

    <div class="mb-2">
      Eddigi legalacsonyabb: {{ set.lowest }} Ft
    </div>

    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">
              Üzlet
            </th>

            <th class="text-left">
              Ár
            </th>

            <th class="text-left">
              Dátum (utoljára változott)
            </th>
          </tr>
        </thead>

        <tbody>
          <tr
            v-for="(store, index) in set.stores"
            :key="index"
            :class="storeInView.indexOf(store.name) > -1 ? 'viewed' : ''"
          >
            <td>{{ store.name }}</td>
            <td>{{ store.last.price }} Ft</td>
            <td>{{ store.last.date }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <template v-if="set.notInStores.length">
      <v-subheader>Jelenleg nem kapható</v-subheader>

      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                Üzlet
              </th>

              <th class="text-left">
                Dátum (utoljára változott)
              </th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="(store, index) in set.notInStores"
              :key="index"
              :class="storeInView.indexOf(store.name) > -1 ? 'viewed' : ''"
            >
              <td>{{ store.name }}</td>
              <td>{{ store.last.date }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </template>

    <div style="margin: 30px;">
      <apexchart type="line" :options="chartOptions" :series="series" />
    </div>
  </v-container>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      set: {
        notInStores: [],
      },
      storeInView: [
        'kocka',
        'kockashop',
        'kockaaruhaz',
        'alza',
        'emag',
      ],
      chartOptions: {
        chart: {
          id: 'vuechart-example',
        },
        xaxis: {
          type: 'datetime',
        },
        theme: {
          mode: 'dark',
        },
      },
      series: [],
    };
  },

  methods: {
    async load() {
      const a = axios.create({
        withCredentials: true,
        baseURL: process.env.VUE_APP_BACKEND_HOST,
      });
      this.set = await a.get(`/lego/${this.$route.params.id}`).then((res) => res.data);

      console.log(this.set.stores[0].data[0].date, (new Date(this.set.stores[0].data[0].date)));

      this.series = this.set.stores.map((store) => ({
        name: store.name,
        data: store.data.map((d) => ({
          x: (new Date(d.date)).getTime(),
          y: d.price === '-' ? 0 : d.price,
        })),
      }));
    },
  },

  mounted() {
    this.load();
  },
};
</script>

<style>
tr.viewed td {
  /* border-top: thin solid white !important;
  border-bottom: thin solid white !important; */
  background-color: #444;
}
</style>
